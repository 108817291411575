import { Collections, CollectionKey } from './types'

const collections: Collections = {
  [CollectionKey.AFRA]: {
    name: 'Afra Bunnies',
    slug: 'afra-bunnies',
    address: {
      56: '',
      97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    },
  },
  [CollectionKey.SQUAD]: {
    name: 'Afra Squad',
    description: "AfraSwap's first official generative NFT collection.. Join the squad.",
    slug: 'afra-squad',
    address: {
      56: '',
      97: '0xEf12ef570300bFA65c4F022deAaA3dfF4f5d5c91',
    },
  },
}

export default collections
