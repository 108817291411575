export default {
  masterChef: {
    97: '0x259C852834375864b65202375558AB11B2d330fd',
    56: '0x259C852834375864b65202375558AB11B2d330fd',
  },
  sousChef: {
    97: '',
    56: '',
  },
  lotteryV2: {
    97: '0xdCf42FE55A07dE7Bf75661Ee1ac3CF8f2AB8Ea3b',
    56: '0xdCf42FE55A07dE7Bf75661Ee1ac3CF8f2AB8Ea3b',
  },
  multiCall: {
    56: '0xdCf42FE55A07dE7Bf75661Ee1ac3CF8f2AB8Ea3b',
    97: '0xdCf42FE55A07dE7Bf75661Ee1ac3CF8f2AB8Ea3b',
  },
  afraProfile: {
    56: '0xa999668a7d7dDaAfa1F6590Cf32f142144Da645c',
    97: '0xa999668a7d7dDaAfa1F6590Cf32f142144Da645c',
  },
  afraRabbits: {
    56: '',
    97: '',
  },
  bunnyFactory: {
    56: '',
    97: '',
  },
  claimRefund: {
    56: '',
    97: '',
  },
  pointCenterIfo: {
    56: '',
    97: '',
  },
  bunnySpecial: {
    56: '',
    97: '',
  },
  tradingCompetition: {
    56: '',
    97: '',
  },
  easterNft: {
    56: '',
    97: '',
  },
  afraVault: {
    56: '0xa12515146944A3CaF5d17f1D006531c8610beCAd',
    97: '0xa12515146944A3CaF5d17f1D006531c8610beCAd',
  },
  predictions: {
    56: '0x74ff2f663e3530C33424d488a549726B53b37B82',
    97: '0x74ff2f663e3530C33424d488a549726B53b37B82',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526',
  },
  bunnySpecialAfraVault: {
    56: '',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '',
    97: '',
  },
  bunnySpecialLottery: {
    56: '',
    97: '',
  },
  farmAuction: {
    56: '0xA481a749334de6C80A656529ae46c75b780725C2',
    97: '0xA481a749334de6C80A656529ae46c75b780725C2',
  },
  AnniversaryAchievement: {
    56: '',
    97: '',
  },
  nftMarket: {
    56: '0x4279bD15F10102f2eA730E800783Eec5C98aF3F6',
    97: '0x4279bD15F10102f2eA730E800783Eec5C98aF3F6',
  },
  nftSale: {
    56: '',
    97: '',
  },
  afraSquad: {
    56: '',
    97: '',
  },
}
