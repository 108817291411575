import { Token, ChainId } from '@afraswap/sdk'
import tokens from './tokens'
import farms from './farms'
import { Ifo } from './types'

const afraBnbLpToken = new Token(ChainId.MAINNET, farms[1].lpAddresses[ChainId.MAINNET], 18, farms[1].lpSymbol)

const ifos: Ifo[] = [
  
]

export default ifos
