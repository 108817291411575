const getTokenLogoURL = (address: string) => {

  if(address === "0x5badD826AeFa700446Fa6d784e6ff97eD6eeDca9"){
    return `https://afraswap.io/images/afra.png`
  }
  return `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`
}
  

export default getTokenLogoURL
