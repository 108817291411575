import React from 'react'
import { Trade } from '@afraswap/sdk'
import { Text } from '@afraswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { AutoColumn } from 'components/Layout/Column'
import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Layout/Row'

function TradeSummary({ trade, allowedSlippage, aggPrice, decimals, aggInitPrice }: { trade: Trade; allowedSlippage: number;aggPrice:number; decimals:number; aggInitPrice:number }) {
  const { t } = useTranslation()

  return (
    <AutoColumn style={{ padding: '0 16px' }}>
      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {t('Minimum received')}
          </Text>
          <QuestionHelper
            text={t(
              'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
            )}
            ml="4px"
          />
        </RowFixed>
        <RowFixed>
          <Text fontSize="14px">
            {aggPrice !==0 ? aggPrice - (aggPrice) * (allowedSlippage/10000) : '-'}
          </Text>
        </RowFixed>
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {t('Liquidity Provider Fee')}
          </Text>
          <QuestionHelper
            text={
              <>
                <Text mb="12px">{t('For each trade a %amount% fee is paid', { amount: '0.25%' })}</Text>

              </>
            }
            ml="4px"
          />
        </RowFixed>
        <Text fontSize="14px">
          {aggInitPrice > 0 ? (aggInitPrice * 0.0025).toFixed(10) : '-'}
        </Text>
      </RowBetween>
    </AutoColumn>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
  aggPrice: number
  decimals: number
  aggInitPrice: number
}

export function AdvancedSwapDetails({ trade, aggInitPrice, aggPrice, decimals   }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  return (
    <AutoColumn gap="0px">
      
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} aggInitPrice={aggInitPrice} aggPrice={aggPrice} decimals={decimals} />
           
        </>
      
    </AutoColumn>
  )
}
