import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.afra,
    earningToken: serializedTokens.afra,
    contractAddress: {
      97: '0x259C852834375864b65202375558AB11B2d330fd',
      56: '0x259C852834375864b65202375558AB11B2d330fd',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.aya,
    earningToken: serializedTokens.afra,
    contractAddress: {
      97: '',
      56: '0x4f040cB4dF13d9f8295B026d3F773e858B47cb9d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '150000',
    sortOrder: 2,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.afra,
    earningToken: serializedTokens.aya,
    contractAddress: {
      97: '',
      56: '0x5Ce92826234d39196ED4d04daC95D20FF780CFa2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '5.43970988',
    sortOrder: 3,
    isFinished: false,
  },
  {
    sousId: 3,
    stakingToken: serializedTokens.aya,
    earningToken: serializedTokens.doge,
    contractAddress: {
      97: '',
      56: '0xD11ce57B5BfcFc319E298Bfe53e759FF1fE6AE34',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02182539',
    sortOrder: 4,
    isFinished: false,
  },
]

export default pools
