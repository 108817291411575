import BigNumber from 'bignumber.js'
import { convertSharesToAfra } from 'views/Pools/helpers'
import { multicallv2 } from 'utils/multicall'
import afraVaultAbi from 'config/abi/afraVault.json'
import { getAfraVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'

export const fetchPublicVaultData = async () => {
  try {
    const calls = [
      'getPricePerFullShare',
      'totalShares',
      'calculateHarvestAfraRewards',
      'calculateTotalPendingAfraRewards',
    ].map((method) => ({
      address: getAfraVaultAddress(),
      name: method,
    }))

    const [[sharePrice], [shares], [estimatedAfraBountyReward], [totalPendingAfraHarvest]] = await multicallv2(
      afraVaultAbi,
      calls,
    )

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    const totalAfraInVaultEstimate = convertSharesToAfra(totalSharesAsBigNumber, sharePriceAsBigNumber)
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalAfraInVault: totalAfraInVaultEstimate.afraAsBigNumber.toJSON(),
      estimatedAfraBountyReward: new BigNumber(estimatedAfraBountyReward.toString()).toJSON(),
      totalPendingAfraHarvest: new BigNumber(totalPendingAfraHarvest.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      pricePerFullShare: null,
      totalAfraInVault: null,
      estimatedAfraBountyReward: null,
      totalPendingAfraHarvest: null,
    }
  }
}

export const fetchVaultFees = async () => {
  try {
    const calls = ['performanceFee', 'callFee', 'withdrawFee', 'withdrawFeePeriod'].map((method) => ({
      address: getAfraVaultAddress(),
      name: method,
    }))

    const [[performanceFee], [callFee], [withdrawalFee], [withdrawalFeePeriod]] = await multicallv2(afraVaultAbi, calls)

    return {
      performanceFee: performanceFee.toNumber(),
      callFee: callFee.toNumber(),
      withdrawalFee: withdrawalFee.toNumber(),
      withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
    }
  } catch (error) {
    return {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}

export default fetchPublicVaultData
