import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'AFRA',
    lpAddresses: {
      97: '0x5badD826AeFa700446Fa6d784e6ff97eD6eeDca9',
      56: '0x5badD826AeFa700446Fa6d784e6ff97eD6eeDca9',
    },
    token: serializedTokens.abra,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'AFRA-BNB LP',
    lpAddresses: {
      97: '0x1Da189c1BA3d718Cc431a2ed240a3753f89CD47A',
      56: '0x1Da189c1BA3d718Cc431a2ed240a3753f89CD47A',
    },
    token: serializedTokens.afra,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0xe606cEE895ddF32b0582A9DC7495176657b4909D',
      56: '0xe606cEE895ddF32b0582A9DC7495176657b4909D',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
  
]

export default farms
