import React from 'react'
import { Flex, Text } from '@afraswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import { usePriceAfraBusd } from 'state/farms/hooks'
import { useAfraVault } from 'state/pools/hooks'
import { getAfraVaultEarnings } from 'views/Pools/helpers'
import RecentAfraProfitBalance from './RecentAfraProfitBalance'

const RecentAfraProfitCountdownRow = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const {
    pricePerFullShare,
    userData: { afraAtLastUserAction, userShares, lastUserActionTime },
  } = useAfraVault()
  const afraPriceBusd = usePriceAfraBusd()
  const { hasAutoEarnings, autoAfraToDisplay, autoUsdToDisplay } = getAfraVaultEarnings(
    account,
    afraAtLastUserAction,
    userShares,
    pricePerFullShare,
    afraPriceBusd.toNumber(),
  )

  const lastActionInMs = lastUserActionTime && parseInt(lastUserActionTime) * 1000
  const dateTimeLastAction = new Date(lastActionInMs)
  const dateStringToDisplay = dateTimeLastAction.toLocaleString()

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text fontSize="14px">{`${t('Recent AFRA profit')}:`}</Text>
      {hasAutoEarnings && (
        <RecentAfraProfitBalance
          afraToDisplay={autoAfraToDisplay}
          dollarValueToDisplay={autoUsdToDisplay}
          dateStringToDisplay={dateStringToDisplay}
        />
      )}
    </Flex>
  )
}

export default RecentAfraProfitCountdownRow
