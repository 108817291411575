import { IPFS_GATEWAY } from '../index'
import { Team } from './types'

const teams: Team[] = [
  {
    id: 1,
    name: 'Abra Storm',
    description: "The storm's a-comin! Watch out! These bulls are stampeding in a abray surge!",
    images: {
      lg: 'abra-storm-lg.png',
      md: 'abra-storm-md.png',
      sm: 'abra-storm-sm.png',
      alt: 'abra-storm-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/abra-storm.png`,
    },
    background: 'abra-storm-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
  {
    id: 2,
    name: 'Fearsome Flippers',
    description: "The flippening is coming. Don't get in these bunnies' way, or you'll get flipped, too!",
    images: {
      lg: 'fearsome-flippers-lg.png',
      md: 'fearsome-flippers-md.png',
      sm: 'fearsome-flippers-sm.png',
      alt: 'fearsome-flippers-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/fearsome-flippers.png`,
    },
    background: 'fearsome-flippers-bg.svg',
    textColor: '#FFFFFF',
    users: 0,
    points: 0,
  },
  {
    id: 3,
    name: 'Chaotic Afrars',
    description: 'Can you stand the heat? Stay out of the kitchen or you might get burned to a crisp!',
    images: {
      lg: 'chaotic-afrars-lg.png',
      md: 'chaotic-afrars-md.png',
      sm: 'chaotic-afrars-sm.png',
      alt: 'chaotic-afrars-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/chaotic-afrars.png`,
    },
    background: 'chaotic-afrars-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
]

export default teams
