import React from 'react'
import { Price } from '@afraswap/sdk'
import { Text, AutoRenewIcon } from '@afraswap/uikit'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
  aggEstimate: number
  aggEstimate2: number
  decimal1: number
  decimal2: number
}

export default function TradePrice({ price, showInverted, setShowInverted, aggEstimate, aggEstimate2, decimal1, decimal2 }: TradePriceProps) {
  
  let estimate1 = aggEstimate2
  let estimate2 = aggEstimate
  if(decimal1 !== decimal2){
    if(decimal1 > decimal2){
      estimate1 = aggEstimate * (10** (decimal1-decimal2))
      estimate2 = aggEstimate2 / (10** (decimal1-decimal2))
    }
    else{
      estimate1 = aggEstimate / (10** (decimal2-decimal1))
      estimate2 = aggEstimate2 * (10** (decimal2-decimal1))
    }
  }

  const formattedPrice = showInverted ? estimate2.toFixed(10) : estimate1.toFixed(10)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

  return (
    <Text style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <AutoRenewIcon width="14px" />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
