import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'AfraSwap',
  description:
    'The most popular AMM on BSC by user count! Earn AFRA through yield farming or win it in the Lottery, then stake it in Abra Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by AfraSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://afraswap.io/images/afrabot.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/afra-squad')) {
    basePath = '/afra-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('AfraSwap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('AfraSwap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('AfraSwap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('AfraSwap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('AfraSwap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('AfraSwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('AfraSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('AfraSwap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('AfraSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('AfraSwap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('AfraSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('AfraSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('AfraSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('AfraSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('AfraSwap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('AfraSwap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('AfraSwap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('AfraSwap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('AfraSwap Info & Analytics')}`,
        description: 'View statistics for Afraswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('AfraSwap Info & Analytics')}`,
        description: 'View statistics for Afraswap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('AfraSwap Info & Analytics')}`,
        description: 'View statistics for Afraswap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('AfraSwap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('AfraSwap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('AfraSwap')}`,
      }
    case '/afra-squad':
      return {
        title: `${t('Afra Squad')} | ${t('AfraSwap')}`,
      }
    default:
      return null
  }
}
