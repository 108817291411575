import BigNumber from 'bignumber.js'
import { getAfraVaultContract } from 'utils/contractHelpers'

const afraVaultContract = getAfraVaultContract()

const fetchVaultUser = async (account: string) => {
  try {
    const userContractResponse = await afraVaultContract.userInfo(account)
    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      afraAtLastUserAction: new BigNumber(userContractResponse.afraAtLastUserAction.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      afraAtLastUserAction: null,
    }
  }
}

export default fetchVaultUser
