import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { SwapParameters, Trade } from '@afraswap/sdk'
import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useGasPrice } from 'state/user/hooks'
import { AGGREGATOR_ADDRESS, BIPS_BASE, INITIAL_ALLOWED_SLIPPAGE } from '../config/constants'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils'
import useENS from './ENS/useENS'
import { useCallWithGasPrice } from './useCallWithGasPrice'
import { useAggregatorContract } from './useContract'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID,
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  aggRoute,
  fromETH: boolean,
  toETH: boolean,
  fromCurrency: string,
  toCurrency: string,
  fromDecimals: number,
  toDecimals: number,

): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const aggregatorContract = useAggregatorContract(AGGREGATOR_ADDRESS, true)
  const gasPrice = useGasPrice()
  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      }
      return { state: SwapCallbackState.LOADING, callback: null, error: null }
    }

    const retswap = async function doswap(): Promise<string> {
      if(fromETH){
        const value = aggRoute[0][0][0].toHexString()
        const toValue = `0x0${((aggRoute[0][0][aggRoute[0][0].length -1]) - Math.trunc((aggRoute[0][0][aggRoute[0][0].length -1]) * (allowedSlippage/10000))).toString(16)}`
        const estimatedGas = await aggregatorContract.estimateGas.swapFromBNB([aggRoute[0][0][0],toValue,aggRoute[0][2],aggRoute[0][1]], recipient, { value } ).catch((error) => {
          throw new Error('Price Impact too high or Insufficient Slippage , please change the amount or increase slippage!')
        })
        return callWithGasPrice(
          aggregatorContract,
          'swapFromBNB',
          [[aggRoute[0][0][0],toValue,aggRoute[0][2],aggRoute[0][1]], recipient],
          {
            gasLimit: calculateGasMargin(estimatedGas),
            value: aggRoute[0][0][0].toHexString() ,
          },
        ).then((response: any) => {
          const base = `Swap ${aggRoute[0][0][0] / (10**18)} BNB for ${aggRoute[0][0][aggRoute[0][0].length -1] / (10**toDecimals)} ${toCurrency}`
          addTransaction(response, {
            summary: base,
          })
          return response.hash
        })
        .catch((error: Error) => {
          console.error('Failed to SWAP token', error)
          throw error
        })
      }
    
      if(toETH){
        const toValue = `0x0${((aggRoute[0][0][aggRoute[0][0].length -1]) - Math.trunc((aggRoute[0][0][aggRoute[0][0].length -1]) * (allowedSlippage/10000))).toString(16)}`
        const estimatedGas = await aggregatorContract.estimateGas.swapToBNB([aggRoute[0][0][0],toValue,aggRoute[0][2],aggRoute[0][1]], recipient).catch((error) => {
          throw new Error('Price Impact too high or Insufficient Slippage , please change the amount or increase slippage!')
        })
        return callWithGasPrice(
          aggregatorContract,
        'swapToBNB',
          [[aggRoute[0][0][0],toValue ,aggRoute[0][2],aggRoute[0][1]], recipient],
          {
            gasLimit: calculateGasMargin(estimatedGas),
          },
        ).then((response: any) => {
          const base = `Swap ${aggRoute[0][0][0] / (10**fromDecimals)} ${fromCurrency} for ${aggRoute[0][0][aggRoute[0][0].length -1] / (10**18)} BNB`
          addTransaction(response, {
            summary: base,
          })
          return response.hash
        })
        .catch((error: Error) => {
          console.error('Failed to SWAP token', error)
          throw error
        })
      }
      const toValue = `0x0${((aggRoute[0][0][aggRoute[0][0].length -1]) - Math.trunc((aggRoute[0][0][aggRoute[0][0].length -1]) * (allowedSlippage/10000))).toString(16)}`
      const estimatedGas = await aggregatorContract.estimateGas.swap([aggRoute[0][0][0],toValue ,aggRoute[0][2],aggRoute[0][1]], recipient).catch((error) => {
        throw new Error('Price Impact too high or Insufficient Slippage , please change the amount or increase slippage!')
      })
      return callWithGasPrice(
        aggregatorContract,
        'swap',
        [[aggRoute[0][0][0],toValue ,aggRoute[0][2],aggRoute[0][1]], recipient],
        {
          gasLimit: calculateGasMargin(estimatedGas),
        },
      ).then((response: any) => {
        const base = `Swap ${aggRoute[0][0][0] / (10**fromDecimals)} ${fromCurrency}  for ${aggRoute[0][0][aggRoute[0][0].length -1] / (10**toDecimals)} ${toCurrency}`
        addTransaction(response, {
          summary: base,
        })
          return response.hash
        })
      .catch((error: Error) => {
        console.error('Failed to SWAP token', error)
        throw error
      })
      
    }

    return {
      state: SwapCallbackState.VALID,
      callback: retswap,
      error: null,
    }
  
    
    
  }, [ library, account, chainId, recipient, recipientAddressOrName, addTransaction, aggRoute, aggregatorContract, callWithGasPrice, fromETH, toETH, fromCurrency, toCurrency, fromDecimals, toDecimals, allowedSlippage])
}
